var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "broadcast" },
    [
      _c("content-wrapper-block", [
        _c(
          "div",
          { staticClass: "broadcast-header" },
          [
            _c("page-header", {
              attrs: { title: "Онлайн трансляция", dark: "", "no-padding": "" },
            }),
          ],
          1
        ),
      ]),
      _c(
        "content-wrapper-block",
        { staticClass: "video__box", attrs: { "no-padding": "" } },
        [
          _vm.error
            ? _c(
                "ContentWrapperBlock",
                { staticClass: "item__video video__error" },
                [
                  _c("div", { staticClass: "video__error-content" }, [
                    _c("img", {
                      staticClass: "video__error-image",
                      attrs: {
                        src: require("@/assets/images/icons/failed_camera.svg"),
                      },
                    }),
                    _c("p", { staticClass: "video__error-text" }, [
                      _vm._v(
                        " Онлайн-трансляция хода строительства в данный момент "
                      ),
                      _c("br"),
                      _vm._v(
                        " приостановлена. Наша бригада принимает все меры для "
                      ),
                      _c("br"),
                      _vm._v(" оперативного устранения неполадок "),
                    ]),
                  ]),
                ]
              )
            : _c(
                "div",
                _vm._l(_vm.locations, function (location, index) {
                  return _c("div", { key: index }, [
                    _c("iframe", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeTab == location.broadcast,
                          expression: "activeTab == location.broadcast",
                        },
                      ],
                      staticClass: "item__video",
                      attrs: {
                        width: "100%",
                        height: "100%",
                        frameborder: "0",
                        allowfullscreen: true,
                        src: location.video,
                      },
                    }),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeTab == location.broadcast,
                            expression: "activeTab == location.broadcast",
                          },
                        ],
                        staticClass: "video__box--info",
                      },
                      [_vm._v(" " + _vm._s(location.info) + " ")]
                    ),
                  ])
                }),
                0
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }