var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "online" },
    [
      _vm.storage.building_broadcast
        ? _c(
            "div",
            _vm._l(_vm.storage.building_broadcast.list, function (item, i) {
              return _c("online-broadcast-block", {
                key: i,
                attrs: {
                  title: "Онлайн трансляция",
                  "initial-tab": "Вид с юга",
                  locations: [
                    {
                      video: item.url,
                      broadcast: "Вид с юга",
                      info: "Строительные работы ведутся с 09:00 до 21:00 часа по МСК",
                    },
                  ],
                  error: item.active_stub,
                },
              })
            }),
            1
          )
        : _vm._e(),
      _c("best-apartments-block"),
      _c("callback-block", {
        attrs: { id: "callback", "id-formblock": "question-block__callback" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }