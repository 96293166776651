<template>
  <div class="online">
    <div v-if="storage.building_broadcast">
      <online-broadcast-block
        v-for="(item, i) in storage.building_broadcast.list"
        :key="i"
        title="Онлайн трансляция"
        initial-tab="Вид с юга"
        :locations="[
          {
            video: item.url,
            broadcast: 'Вид с юга',
            info: 'Строительные работы ведутся с 09:00 до 21:00 часа по МСК',
          },
        ]"
        :error="item.active_stub"
      />
    </div>
    <best-apartments-block />
    <callback-block id="callback" id-formblock="question-block__callback" />
  </div>
</template>

<script>
import OnlineBroadcastBlock from "@/components/blocks/OnlineBroadcastBlock";
import CallbackBlock from "@/components/blocks/CallbackBlock";
import BestApartmentsBlock from "@/components/blocks/BestApartmentsBlock";
import { mapState } from "vuex";

export default {
  name: "OnlineBroadcastPage",
  components: {
    BestApartmentsBlock,
    OnlineBroadcastBlock,
    CallbackBlock,
  },
  computed: {
    ...mapState({
      storage: (state) => state.storage,
    }),
  },
};
</script>

<style lang="scss" scoped>
.online {
  .offers {
    padding-bottom: 60px;
  }
}
</style>
