<template>
  <div class="broadcast">
    <content-wrapper-block>
      <div class="broadcast-header">
        <page-header title="Онлайн трансляция" dark no-padding>
          <!-- <ui-tab-bar v-model="activeTab">
            <ui-tab-bar-tab v-for="(tab, index) in locations" :key="index" :value="tab.broadcast">
              {{tab.broadcast}}
            </ui-tab-bar-tab>
          </ui-tab-bar> -->
        </page-header>
      </div>
    </content-wrapper-block>

    <content-wrapper-block no-padding class="video__box">
      <ContentWrapperBlock v-if="error" class="item__video video__error">
        <div class="video__error-content">
          <img
            class="video__error-image"
            src="@/assets/images/icons/failed_camera.svg"
          />
          <p class="video__error-text">
            Онлайн-трансляция хода строительства в данный момент <br />
            приостановлена. Наша бригада принимает все меры для <br />
            оперативного устранения неполадок
          </p>
        </div>
      </ContentWrapperBlock>
      <div v-else>
        <div v-for="(location, index) in locations" :key="index">
          <iframe
            v-show="activeTab == location.broadcast"
            class="item__video"
            width="100%"
            height="100%"
            frameborder="0"
            :allowfullscreen="true"
            :src="location.video"
          />
          <div
            v-show="activeTab == location.broadcast"
            class="video__box--info"
          >
            {{ location.info }}
          </div>
        </div>
      </div>
    </content-wrapper-block>
  </div>
</template>

<script>
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import PageHeader from "@/components/elements/PageHeader";
// import UiTabBar from '@/components/ui/UITabBar'
// import UiTabBarTab from '@/components/ui/UITabBarTab'

export default {
  name: "OnlineBroadcastBlock",
  components: {
    ContentWrapperBlock,
    PageHeader,
    // UiTabBar,
    // UiTabBarTab
  },
  props: {
    // title: String,
    initialTab: String,
    locations: {
      type: Array,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    activeTab: "",
  }),
  created() {
    this.activeTab = this.initialTab;
  },
};
</script>

<style lang="scss" scoped>
.broadcast {
  background: #231f20;
  padding-bottom: 40px;

  @media screen and (min-width: $screen-mini) {
    padding-bottom: 70px;
  }

  &-header {
    align-items: center;

    @media screen and (min-width: $screen-mini) {
      display: flex;
    }

    .ui-tab-bar {
      height: 45px;
      margin-left: 0px;

      @media screen and (min-width: $screen-xs) {
        margin-left: 40px;
      }
    }
  }

  .video__error {
    &-image {
      margin-top: auto;
      height: 50%;
      @media screen and (min-width: $screen-mini) {
        height: auto;
      }
    }
    &-text {
      margin-bottom: auto;
      color: #fff;
      font-size: 90%;
      @media screen and (min-width: $screen-xs) {
        font-size: inherit;
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
    }
  }

  .video__box {
    position: relative;

    .item__video {
      height: 193px;

      @media screen and (min-width: $screen-mini) {
        height: 573px;
      }
    }

    &--info {
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      font-family: Rubik;
      font-size: 16px;
      color: #fff;
      background: rgba(0, 0, 0, 0.39);
      padding: 12px 16px;
    }
  }
}
.video_wrapper {
  position: inherit !important;
}
</style>
